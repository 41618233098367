import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/video"
import { ItchioLogo, SteamLogo } from "../components/svgs/logo"
import SocialImage from "../images/owgtowg/capsule_main.png"

const OWGTOWGPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO title="Open World Game: the Open World Game" image={SocialImage} />
      <div id="owgtowg" className="header inner-shadow">
        <div className="container ">
          <Img
            style={{ marginTop: "0" }}
            fluid={data.owgtowg.childImageSharp.fluid}
            alt="Open World Game: the Open World Game logo"
          />
        </div>
      </div>
      <div className="banner-game-preview" style={{ marginBottom: "0" }}>
        <div className="container hero hero-columns">
          <div className="two-col">
            <Video
              videoSrcURL="https://www.youtube.com/embed/N3wcgS6hs7Q"
              videoTitle="Open World Game: the Open World Game trailer"
              classes="video"
              SameSite="Lax"
              styles={{ marginBottom: "0" }}
            />
          </div>
          <div className="two-col flex-center-center">
            <p>
              <b>Open World Game: the Open World Game</b> is the purest open
              world game experience. Enjoy simplified mechanics, minimal
              graphics, and an extremely short main story; all so you can get to
              work removing every icon from the map with the least resistance.
            </p>
            <div className="store-logos">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://store.steampowered.com/app/1144110/Open_World_Game_the_Open_World_Game/"
              >
                <SteamLogo fill="#eee" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://morningpersongames.itch.io/owgtowg"
              >
                <ItchioLogo fill="#eee" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="secondary-background" style={{ marginBottom: "1em" }}>
        <div className="container hero hero-columns image-collection">
          <a
            className="four-col"
            href={data.screen1.childImageSharp.original.src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="Open World Game: the Open World Game screenshot: picking up basic object"
              fluid={data.screen1.childImageSharp.fluid}
            />
          </a>
          <a
            className="four-col"
            href={data.screen2.childImageSharp.original.src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="Open World Game: the Open World Game screenshot: fishing"
              fluid={data.screen2.childImageSharp.fluid}
            />
          </a>
          <a
            className="four-col"
            href={data.screen3.childImageSharp.original.src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="Open World Game: the Open World Game screenshot: skill tree"
              fluid={data.screen3.childImageSharp.fluid}
            />
          </a>
          <a
            className="four-col"
            href={data.screen4.childImageSharp.original.src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="Open World Game: the Open World Game screenshot: level up!"
              fluid={data.screen4.childImageSharp.fluid}
            />
          </a>
        </div>
      </div>
      <div className="text-center">
        <h3
          className="min-width-heading border-gradient-separator border-gradient-sunrise"
          style={{ marginBottom: "0" }}
        >
          Reviews
        </h3>
      </div>
      <div className="container hero hero-columns">
        <div className="three-col quote flex-center-center">
          <p>
            <i>
              "This games has me rethinking the hundreds (thousands?) of hours I
              have spent playing RPGs. What was it all for? 10/10 highly
              recommend this satire, even with the soul searching."
            </i>
          </p>
        </div>
        <div className="three-col quote flex-center-center">
          <p>
            <i>
              "I don’t get this game. You walk around in a world, and that’s
              basically it. Each interaction gives you a completed quest by
              pressing some arrows... That’s it, nothing else is available in
              the game."
            </i>
          </p>
        </div>
        <div className="three-col quote flex-center-center">
          <p>
            <i>
              "This was a strangely interesting game to 100%. I think i played
              it the wrong way though, because it actually made me want to boot
              up a RPG again instead of instructing me on the futility of those
              games."
            </i>
          </p>
        </div>
      </div>
      <div id="owgtowg" className="inner-shadow gratuitous-dlc header">
        <div className="container ">
          <Img
            style={{ marginTop: "0" }}
            fluid={data.gratuitous.childImageSharp.fluid}
            alt="Open World Game: the Open World Game – The Gratuitous DLC logo"
          />
        </div>
      </div>
      <div
        className="banner-game-preview"
        style={{ marginBottom: "0", paddingBottom: "0" }}
      >
        <div
          className="container hero hero-columns"
          style={{ marginBottom: "0", paddingBottom: "0" }}
        >
          <div className="two-col">
            <Video
              videoSrcURL="https://www.youtube.com/embed/yGu4HYyigEg"
              videoTitle="Open World Game: the Open World Game – The Gratutious DLC"
              classes="video"
              SameSite="Lax"
            />
          </div>
          <div className="two-col flex-center-center">
            <p>
              <b>The Gratuitous DLC</b> expands on the Open World Game: the Open
              World Game experience by adding combat, dungeons, and excessively
              intense boss battles.
            </p>
            <div className="store-logos">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://store.steampowered.com/app/1310290/Open_World_Game_the_Open_World_Game__The_Gratuitous_DLC"
              >
                <SteamLogo fill="#eee" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://morningpersongames.itch.io/owgtowg"
              >
                <ItchioLogo fill="#eee" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-border secondary-background">
        <div className="container hero hero-columns image-collection">
          <a
            className="four-col"
            href={data.grat1.childImageSharp.original.src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="Open World Game: the Open World Game – The Gratutious DLC screenshot: monsters"
              fluid={data.grat1.childImageSharp.fluid}
            />
          </a>
          <a
            className="four-col"
            href={data.grat2.childImageSharp.original.src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="Open World Game: the Open World Game – The Gratutious DLC screenshot: dungeon fight"
              fluid={data.grat2.childImageSharp.fluid}
            />
          </a>
          <a
            className="four-col"
            href={data.grat3.childImageSharp.original.src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="Open World Game: the Open World Game – The Gratutious DLC screenshot: boss fight"
              fluid={data.grat3.childImageSharp.fluid}
            />
          </a>
          <a
            className="four-col"
            href={data.grat4.childImageSharp.original.src}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              alt="Open World Game: the Open World Game – The Gratutious DLC screenshot: another dungeon fight"
              fluid={data.grat4.childImageSharp.fluid}
            />
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default OWGTOWGPage

export const pageQuery = graphql`
  query {
    owgtowg: file(relativePath: { eq: "Logo_transparent.png" }) {
      childImageSharp {
        fluid(maxWidth: 956) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gratuitous: file(
      relativePath: { eq: "owgtowg/gratuitous_dlc_transparent.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 956) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    screen1: file(relativePath: { eq: "owgtowg/arrows.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        original {
          src
        }
      }
    }
    screen2: file(relativePath: { eq: "owgtowg/fish.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
        original {
          src
        }
      }
    }
    screen3: file(relativePath: { eq: "owgtowg/skilltree.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
        original {
          src
        }
      }
    }
    screen4: file(relativePath: { eq: "owgtowg/levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
        original {
          src
        }
      }
    }
    grat1: file(relativePath: { eq: "owgtowg/monstos.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
        original {
          src
        }
      }
    }
    grat2: file(relativePath: { eq: "owgtowg/dungeonCombatmore.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
        original {
          src
        }
      }
    }
    grat3: file(relativePath: { eq: "owgtowg/bosses.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
        original {
          src
        }
      }
    }
    grat4: file(relativePath: { eq: "owgtowg/dungeonCombat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
        original {
          src
        }
      }
    }
  }
`
